import { graphql } from 'gatsby'
import React from 'react'
import Img from 'gatsby-image'
import Header from '../components/header'
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import '../styles/app.scss'





export const query = graphql `
  query ($slug: String!) {
    contentfulHomePageCase(slug: {eq: $slug}) {
      caseTitle
      caseText{
        json
      }
      caseImage{
        fluid{
          ...GatsbyContentfulFluid
        }
      }
      videoUrlOne
      videoUrlTwo
    }
  }

`
const Case = (props) => {
  const videoExists = !!props.data.contentfulHomePageCase.videoUrlOne;
  
  return(
    <section>
    <Header/>
    <div className="container-small">
      <div className="row text-center case-text">
        <div className="column column-text">
          <h1>{props.data.contentfulHomePageCase.caseTitle}</h1>
          <div>{documentToReactComponents(props.data.contentfulHomePageCase.caseText.json)}</div>
        </div>
      </div>
      <div className="row text-center case-text">
        <div className="column column-text">
            <Img fluid={props.data.contentfulHomePageCase.caseImage.fluid}/>
        </div>
      </div>
      {videoExists && (
        <div className="row case-text">
          <div className="column">
              <video controls playsInline loop id="video" src={props.data.contentfulHomePageCase.videoUrlOne}></video>
          </div>
        </div>
      )}
    </div>
    
    </section>
    )

}

export default Case;
